.spinner-overlay {
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-overlay .spinner-top {
  position: absolute;
  top: 0;
  left: 0;
}

.spinner-overlay .spinner-bottom {
  position: absolute;
  bottom: 0;
  text-align: center;
}

.spinner-overlay .spinner-bottom a {
  text-decoration: none;
}

.spinner-overlay .spinner-bottom a:hover {
  color: #7ac143;
}

.spinner-overlay .spinner-bottom .prev-step {
  vertical-align: bottom;
}

.spinner-overlay .spinner-bottom .fa {
  font-size: 28px;
  vertical-align: middle;
  margin-right: 10px;
}

.spinner-overlay .spinner-conteiner {
  width: 171px;
  height: 176px;
  display: inline-block;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  z-index: 9999;
}

@keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
}