/* General CSS edit */
body {
  color: #ffffff;
  background-color: #002e52;
}

* {
  box-sizing: border-box;
}

#root {
  height: 100vh;
}
/* End of General CSS */

/* Common Classes CSS */
.section-center {
  float: none;
  margin: 0 auto;
}

.white-text {
  color: #ffffff;
}

.gray-text {
  color: #465563;
}

.blue-text {
  color: #3cafc2;
}

.green-text {
  color: #7ac143;
}

.orange-text {
  color: #f39200;
}

.dark-blue-text {
  color: #002e52;
}

.bg-light-gray {
  background-color: #ebebeb;
}

.bg-blue {
  background-color: #3cafc2;
}

.bg-green {
  background-color: #7ac143;
}

.bg-orange {
  background-color: #f39200;
}
/* End of Common Classes CSS */
